import React,{ useRef, useEffect } from 'react'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { Texture } from 'three'


const Scene = () => {

  const mountRef = useRef(null);

  useEffect(() => {

    const currentMount = mountRef.current

    //Scene
    const scene = new THREE.Scene();
    //scene.background = new THREE.Color(0x0094FF)
    //scene.background = new THREE.Color(0xD8D8D8)
   scene.background = new THREE.Color(0x153FA1)
    const camera = new THREE.PerspectiveCamera(
      25,
      currentMount.clientWidth / currentMount.clientHeight,
      0.1,
      1000
    )
    camera.position.x = 2.0
    camera.position.y = 3
    camera.position.z = 4.3
    scene.add(camera)

    //Renderer
    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(
       currentMount.clientWidth,
       currentMount.clientHeight
    )
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.shadowMap.enabled =true
    renderer.shadowMap.type = THREE.PCFShadowMap
    renderer.physicallyCorrectLights = true
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure =1.5
    renderer.setPixelRatio(2)

    currentMount.appendChild(renderer.domElement)
    
    //Controls
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.target = new THREE.Vector3(0.5,0.5,0)
    controls.enableDamping = true

    //Resize
    const resize = () =>{
      renderer.setSize(currentMount.clientWidth,currentMount.clientHeight)
      camera.aspect = currentMount.clientWidth/currentMount.clientHeight
      camera.updateProjectionMatrix()
    }
    window.addEventListener('resize',resize,false)

    let example = new THREE.Object3D()
    const material = new THREE.MeshBasicMaterial()
 
    let env = new  THREE.CubeTextureLoader()
    const cubeTextureLoader = new THREE.CubeTextureLoader()
    cubeTextureLoader.load([
        './envMap/px.jpg',
        './envMap/nx.jpg',
        './envMap/py.jpg',
        './envMap/ny.jpg',
        './envMap/pz.jpg',
        './envMap/nz.jpg'],(onload)=>{
          console.log("carga completada")
         env = onload
        })
    
    const hive =  new THREE.Group()
    
       //Loader
       const dracoLoader = new DRACOLoader();
       dracoLoader.setDecoderPath('./draco/')
   
       const gltfLoader = new GLTFLoader ()
       gltfLoader.setDRACOLoader(dracoLoader)
      

    gltfLoader.load('./model/UG.gltf',
    //onLoad
    (object) =>{
      console.log("cargado modelo completado")
       console.log(object)
       //object.scale.set(1, 1,  1)
       
      example = object
       //scene.add(example.scene)
     while(object.scene.children.length ){
       console.log(object.scene.children)
      if(object.scene.children > 0){
        console.log("si entre")
       example.scene.children[0].material.envMap = env
      }
      //object.scene.children[0].material.envMap = evp
      //object.scene.children[0].material.envMap = env
        hive.add(object.scene.children[0])
     }
     scene.add(hive)
      /* object.traverse(function (child) {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
               child.material = material
               if(child.material){
                child.material.transparent = false;
              }
            }
           
         })*/
         
         
      
    },
    //onProgress
    ()=>{
      console.log("cargando")
    },
    //onError
    (err)=>{
      console.log("fallo la carga" + err)
    }
    

    ) 
  

      //Lights
      const AO = new THREE.AmbientLight(0xffffff,0.8)
      scene.add(AO)

      const light = new THREE.PointLight()
      light.position.set(2.5,2.5, 0)
      light.intensity = 4
      scene.add(light)


      

    //Render the Scene
    const animate = () => {
        controls.update()
        renderer.render(scene,camera)
        requestAnimationFrame(animate)
    }
    animate()    

    //clean up de useffect
      return()=>{
        currentMount.removeChild(renderer.domElement)
      }

  }, [])

  return (
    <div className='Contenedor3D'
      ref={mountRef}
      style={{ width: '100%', height: '100vh' }}
    >
    

    </div>
  )
}

export default Scene