import React from 'react' 
import Menu from './components/Menu/Menu';
import Scene from './components/Scene';

function App() {
  return (
   <>
    <Scene/>
    <Menu/>
    </>

  );
}

export default App;
