import React from 'react'
import "./Styles.css"
import Logo from '../../assets/img/logo.png'

const Menu = () => {
  return (
    <div className ="MenuContainer">
      <img src={Logo} style={{width:'200px',height:'auto', paddingLeft:"50px",paddingTop:"25px"}}/>
         <div className ="MenuWrapper">
            <div className ="MenuOptions">
                <h1>Hives</h1>
                 <ul className="MenuOptionsList">
                     <li>
                         <label htmlFor="Hives">Hives</label>
                         <select className="Hives">
                             <option value="ArkHive400+">ArkHive400+</option>
                             <option value="ArkHive800+">ArkHive800+</option>
                        </select>
                    </li>
                 </ul>    

            </div>
         </div>
    </div>
  )
}

export default Menu